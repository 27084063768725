<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('AccountsToBlockClientMessages')"
    @cancel="onCancel('AccountsToBlockClientMessages')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountsToBlockClientMessageEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'AccountsToBlockClientMessages',
      pageHeader: 'Редактирование сотрудника, которому нельзя писать в заявке КСП',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Сотрудники, которым нельзя писать в заявку КСП',
          route: { name: 'AccountsToBlockClientMessages' },
        },
        {
          text: 'Редактирование сотрудника, которому нельзя писать в заявке КСП',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      employeeAccounts: (state) => state.common.employeeAccounts.data,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'accountId',
              label: 'ФИО сотрудника',
              list: this.employeeAccounts
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      () => {
        this.form = this.lodash.cloneDeep(this.item.data);
      }
    );
    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });

    Promise.all([item, employeeAccounts]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'accountId', label: 'Id аккаунта' }];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
};
</script>
